<template lang="pug">
  horizontal-navigation(:items="items" :options="options" )
</template>

<script>
import useNavigation from '@/composition/navigation'
import HorizontalNavigation from '@/components/ui/horizontalNavigation.vue'

export default {
  name: 'horizontalNav',
  components: { HorizontalNavigation },
  setup() {
    return { ...useNavigation() }
  }
}
</script>

<style scoped>

</style>

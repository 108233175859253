export default {
  shops: [
    {
      id: 1,
      label: 'магазин «Индия»',
      address: 'г. Владивосток, ул. Океанский проспект, 69, 1 этаж',
      addressLink: 'https://go.2gis.com/jtsdn',
      phone: {
        value: '+79241222108',
        label: '+7 (924) 122‒21‒08'
      },
      schedule: {
        label: 'График работы магазина:',
        items: [
          { label: 'ПН-ВС', value: '11:00 - 19:00' }
        ]
      }
    },
    {
      id: 2,
      label: 'магазин «VEGGY SHOP»',
      address: 'г. Владивосток, ул. Океанский проспект, 69, 1 этаж',
      addressLink: 'https://go.2gis.com/ayk6b0',
      phone: {
        value: '+79940107108',
        label: '+7 (994) 010‒71‒08'
      },
      schedule: {
        label: 'График работы магазина:',
        items: [
          { label: 'ПН-ВС', value: '10:00 - 21:00' }
        ]
      }
    },
    {
      id: 3,
      label: 'магазин «Ганга и Ганеш»',
      address: 'г. Владивосток, ул. Проспект Красного Знамени, 59, 1 этаж',
      addressLink: 'https://go.2gis.com/o6koj2',
      phone: {
        value: '+79147371080',
        label: '+7 (914) 737‒10‒80'
      },
      schedule: {
        label: 'График работы магазина:',
        items: [
          { label: 'ПН-ПТ', value: '11:00 - 19:00' },
          { label: 'СБ', value: '11:00 - 18:00' }
        ]
      }
    },
    {
      id: 4,
      label: 'магазин «Подиум»',
      address: 'г. Большой Камень, ул. Академика Крылова, 4',
      addressLink: 'https://go.2gis.com/hd30y',
      phone: {
        value: '+79841561786',
        label: '+7 (984) 156‒17‒86'
      },
      schedule: {
        label: 'График работы магазина:',
        items: [
          { label: 'ПН-ВС', value: '10:00 - 19:00' }
        ]
      }
    }
  ]
}

import { reactive, ref, unref } from '@vue/composition-api'

export default function useNavigation() {
  const topLink = ref('#main')
  const contactLink = ref('#contacts')
  const catalogLink = ref('#catalog')
  const shopsLink = ref('#shops')

  return {
    topLink: unref(topLink),
    contactLink: unref(contactLink),
    catalogLink: unref(catalogLink),
    shopsLink: unref(shopsLink),
    items: ref([
      { label: 'Каталог', link: unref(catalogLink) },
      { label: 'Где купить', link: unref(shopsLink) },
      { label: 'Контакты', link: unref(contactLink) }
    ]),
    options: reactive({
      duration: 700,
      offset: 0,
      easing: 'easeInOutCubic'
    })
  }
}

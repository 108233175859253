import { useVuex } from '@vueblocks/vue-use-vuex'

export default function useShops() {
  const { useState } = useVuex('shops')

  const {
    shops
  } = useState(['shops'])

  return {
    shops
  }
}

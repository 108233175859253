<template lang="pug">
  v-container(class="contactsSection")
    h2(class="contactsSection__title") Контакты
    v-row(class="contactsSection__content")
      v-col(cols="12" md="4" class="contactsSection__column")
        .contactsSection__left
          .contactsSection__label Источник
          .contactsSection__desc Соевые свечи ручной работы
      v-col(cols="12" md="4" class="contactsSection__column")
        .contactsSection__center
          a(class="contactsSection__email" href="mailto:istochnik.svechi@gmail.com" target="_blank") istochnik.svechi@gmail.com
          a(class="contactsSection__phone" href="tel:+79146703905" target="_blank") +7 914 670-39-05
          .contactsSection__socials
            a(class="contactsSection__instagram" href="https://instagram.com/istochnik.svechi?igshid=YzA2ZDJiZGQ=" target="_blank")
            a(class="contactsSection__telegram" href="https://t.me/Tyuha" target="_blank")
            a(class="contactsSection__whatsapp" href="https://wa.me/79146703905" target="_blank")
      v-col(cols="12" md="4" class="contactsSection__column")
        .contactsSection__info
          |  Буду рада возможному сотрудничеству!
          br
          br(v-if="$vuetify.breakpoint.smAndDown")
          |  А так же с удовольствием приму
          br(v-if="$vuetify.breakpoint.smAndDown")
          |  предложения по улучшению свечей.
</template>

<script>
export default {
  name: 'contactsSection'
}
</script>

<style lang="scss">
  @import '~vuetify/src/styles/styles.sass';

  .contactsSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 5px;
    padding: 44px 25px;
    min-height: 500px;

    @media #{map-get($display-breakpoints, 'xs-only')} {
      padding: 40px 20px;
    }

    &__title {
      color: var(--v-accent-base);
      margin-bottom: 40px;
      line-height: 1;
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        margin-bottom: 15px;
        align-self: flex-start;
      }
    }

    &__content {
      display: flex;
      flex-direction: row;;
      align-items: center;
    }

    &__column {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 27px;
    }

    &__left, &__center, &__right {
      display: flex;
      flex-direction: column;
      gap: 20px;
      color: var(--v-secondary-base);
      text-align: left;
    }

    &__left {
      gap: 10px;
    }

    &__label {
      font-size: 36px;
      line-height: 1.5;
    }

    &__desc {
      font-size: 14px;
      line-height: 1.5px;
    }

    &__email, &__phone {
      color: var(--v-primary-base);
      font-size: 20px;
      line-height: 24px;
      text-transform: none;
      text-decoration: none;
      width: fit-content;
      border-bottom: 1px dashed transparent;
      transition: all 0.3s;

      &:hover {
        border-color: var(--v-accent-base);
        color: var(--v-accent-base);
      }
      @media #{map-get($display-breakpoints, 'md-only')} {
        font-size: 16px;
      }
    }

    &__socials {
      display: flex;
      flex-direction: row;
      gap: 27px;
      justify-content: flex-start;
      align-items: center;
    }

    &__instagram, &__telegram, &__whatsapp {
      display: inline-flex;
      width: 27px;
      height: 27px;
      background-size: contain!important;
    }

    &__instagram {
      background: url('~assets/contacts/instagram.svg') no-repeat;
    }

    &__telegram {
      background: url('~assets/contacts/telegram.svg');
    }

    &__whatsapp {
      background: url('~assets/contacts/whatsapp.svg');
    }

    &__info {
      @media #{map-get($display-breakpoints, 'xs-only')} {
        font-size: 12px;
      }
    }
  }
</style>

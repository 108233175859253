<template lang="pug">
  v-container(class="topSection")
    v-row(class="justify-start")
      v-col(cols="12" md="5")
        h1(class="topSection__ttl")
          | {{ title }}
        v-row(class="topSection__buttons")
          v-btn(
            class="topSection__top-section-btn"
            depressed
            :color="$vuetify.breakpoint.smAndDown ? '#626C78' : 'backgroundSecond'"
            :width="btnSize.w"
            :height="btnSize.h"
            rounded
            elevation="1"
            @click="$vuetify.goTo(catalogLink, options)"
          )
            span(class="topSection__to-catalog-btn-text topSection__top-section-btn-text") Посмотреть каталог
          v-btn(
            v-if="$vuetify.breakpoint.smAndDown"
            class="topSection__top-section-btn"
            depressed
            color="backgroundSecond"
            :width="btnSize.w"
            :height="btnSize.h"
            rounded
            elevation="1"
            @click="$vuetify.goTo(shopsLink, options)"
          )
            span(class="topSection__top-section-btn-text") Где купить
          v-btn(
          v-if="$vuetify.breakpoint.smAndDown"
            class="topSection__top-section-btn"
            depressed
            color="backgroundSecond"
            :width="btnSize.w"
            :height="btnSize.h"
            rounded
            elevation="1"
            @click="$vuetify.goTo(contactLink, options)"
          )
            span(class="topSection__top-section-btn-text") Связаться

</template>

<script>
import useNavigation from '@/composition/navigation'
import useVuetify from '@/composition/useVuetify'
import { computed, unref } from '@vue/composition-api'

export default {
  name: 'topSection',
  setup() {
    // navigator
    const {
      catalogLink, shopsLink, contactLink, options
    } = useNavigation()

    const { breakpoint } = useVuetify()

    const btnSize = computed(() => {
      if (unref(breakpoint).smAndDown) {
        return { w: '200px', h: 40 }
      }
      return { w: 'fit-content', h: 60 }
    })

    const title = 'Откройте в себе красоту мира, наслаждаясь его ароматами вместе со свечами “Источник”'

    return {
      catalogLink,
      shopsLink,
      contactLink,
      options,
      btnSize,
      title
    }
  }
}
</script>
<style lang="scss">
  @import '~vuetify/src/styles/styles.sass';

  .topSection {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin-top: 5px;
    overflow: hidden;
    min-height: calc(100vh - 60px);

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      padding: 20px;
      align-items: flex-start;
      margin-top: 50px;
      min-height: calc(100vh - 50px);
    }

    &__ttl {
      font-size: 1em;
      font-weight: 500;
      line-height: 1.3;
      color: var(--v-primary-base);
      text-align: center;
      @media #{map-get($display-breakpoints, 'sm-only')} {
        margin: 0 auto;
        max-width: 320px;
        font-size: 1em
      }
      @media #{map-get($display-breakpoints, 'xs-only')} {
        margin: 0 auto;
        max-width: 320px;
        font-size: 1em
      }
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        margin-top: 29px;
      }
      @media #{map-get($display-breakpoints, 'md-and-up')} {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 29px;
      }
    }

    &__top-section-btn {
      border-radius: 50px;
    }

    &__to-catalog-btn-text {
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        color: var(--v-background-base)!important;
      }
    }

    &__top-section-btn-text {
      color: var(--v-secondary-base);
      font-size: 32px;
      font-weight: bold;
      line-height: 1.3;
      letter-spacing: 0.03px;
      padding: 8px 12px;

      @media #{map-get($display-breakpoints, 'md-only')} {
        font-size: 24px;
      }
      @media #{map-get($display-breakpoints, 'sm-only')} {
        font-size: 12px;
      }
      @media #{map-get($display-breakpoints, 'xs-only')} {
        font-size: 12px;
      }
    }
  }
</style>

import { useVuex } from '@vueblocks/vue-use-vuex'

export default function useCatalog() {
  const { useState, useMutations } = useVuex('catalog')

  const {
    catalogItems,
    selectedItem
  } = useState(['catalogItems', 'selectedItem'])

  const { mSetSelectedItem } = useMutations(['mSetSelectedItem'])

  return {
    catalogItems,
    selectedItem,
    mSetSelectedItem
  }
}

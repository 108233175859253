import Vue from 'vue'
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import VueYandexMetrika from 'vue-yandex-metrika'
import VueCompositionAPI from '@vue/composition-api'
import store from '@/store'
import VModal from 'vue-js-modal'
import VideoBackground from 'vue-responsive-video-background-player'
import vuetify from './plugins/vuetify'

Vue.use(VModal)
Vue.config.productionTip = false
Vue.use(VueCompositionAPI)
Vue.component('video-background', VideoBackground)

Vue.use(VueYandexMetrika, {
  id: 90793965,
  router,
  env: process.env.NODE_ENV,
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')

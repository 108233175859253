<template lang="pug">
  v-app-bar(app color="backgroundSecond" dense elevation='6' class="px-0" elevate-on-scroll :height="height" )
    v-container(class="pa-0 fill-height")
      v-row(align="center" justify="space-between" no-gutters)
        v-col(cols="1" class="align-center")
          img(
            class="logo"
            src="@/assets/logos/1.svg"
            alt="Свечи Источник"
            @click="$vuetify.goTo(topLink, options)"
          )
        v-col(cols="auto" md="6" v-if="$vuetify.breakpoint.mdAndUp")
          horizontalNav
        v-spacer
        v-app-bar-nav-icon(v-if="$vuetify.breakpoint.smAndDown" @click.stop="$store.commit('app/toggleOpenedMobileMenu')")
</template>

<script>
import horizontalNav from '@/layout/topBar/horizontalNav.vue'
import { computed, unref } from '@vue/composition-api'
import useVuetify from '@/composition/useVuetify'
import useNavigation from '@/composition/navigation'

export default {
  name: 'topBar.vue',
  components: {
    horizontalNav
  },
  setup() {
    const { breakpoint } = useVuetify()

    const height = computed(() => {
      if (unref(breakpoint).smAndDown) {
        return 50
      }
      return 60
    })

    return {
      height,
      ...useNavigation()
    }
  }
}
</script>

<style lang="scss">
img.logo {
  display: block;
  height: 45px;
}
</style>

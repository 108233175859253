export default {
  catalogItems: [
    {
      id: 1,
      label: 'Северная клюква',
      desc: 'Наполните ароматом спелой сочной клюквы своё пространство. Вспомните о северной свежести, кисловатой сладости. Очистите свой мозг, концентрируясь на одном вопросе. Откройте каналы яснознания и ясновидения, выделив на пути развития мироздания своё предназначение. Будет незаменимым помощником в поисках ответов на вопросы о сути жизни и смысле существования.',
      color: 'розовой'
    },
    {
      id: 2,
      label: 'Мёд и слива',
      desc: 'Войдите в лес сладостей. Расслабьте себя, нежась в энергиях солнечных даров. Почувствуйте веяние пряностей и сочной сливы. Само МироЗдание раскрывает свои объятия, взывая к себе. Лёгкость и нежность, красоту мира и его любовь, откроет нежнейший аромат мёда и сливы.',
      color: 'фиолетовой'
    },
    {
      id: 3,
      label: 'Кашемировое дерево',
      desc: 'Скошенная трава, булочки с сахаром, карамель,свежее сено, запах полей, пудра - это только часть ассоциаций с которыми Вы столкнетесь, наслаждаясь данным ароматом. Деликатные оттенки проведут Вашу душу в путешествие по бескрайним полям, наполненными благоуханием цветов и трав. Вы отправитесь к безбрежным океанам, ощущая оттенки запаха чистой кожи, согретой ласковым солнцем после морского купания. И в завершении насладитесь воспоминанием о нежнейших булочках с карамелью, присыпанной пудрой. Аромат “кашемировое дерево” поможет Вам в самоанализе и оценке сбора опыта этого и прошлых воплощений.',
      color: 'голубой'
    },
    {
      id: 4,
      label: 'Бобы тонка и ваниль',
      desc: 'Ощутите свежесть жизни, погружаясь во вкус лёгкой кислинки, нанизанной на сливочную основу. Полакомитесь сливочным кремом, вспыхнувшим ярким пятном на кристальной чистой основе. Аромат позволит отсеять все сторонние мысли, сконцентрировав Вас на одном вопросе, придав ощущение гармонии и уюта.',
      color: 'молочной'
    },
    {
      id: 5,
      label: 'Карамельный попкорн',
      desc: 'Прикоснитесь к игристой энергии. Почувствуйте подкопчёный аромат с лёгким сладковатым привкусом. Устойчивый запах, переходящий от лёгкого пудрового оттенка до насыщенного фруктово - ягодного вкуса наполнит пространство энергией жизни, раскрывая многовариантность проявления Творца.',
      color: 'оранжевой'
    },
    {
      id: 6,
      label: 'Мамин какао',
      desc: 'Наполните мозг сладостью шоколада. Получите безграничную энергию, вдыхая аромат свежеперемолотых какао-бобов. Запах восстановит ваши силы, придаст искру желания, побуждая к действию. Снимет напряжение, напоминая Вам вкусы детства и уютные уголки проявленного мира, где Вы испытывали изысканные ощущения, погружаясь в мир маленьких кофейн и сладких кулинарных пряностей.',
      color: 'фиолетовой'
    },
    {
      id: 7,
      label: 'Мох и папоротник ',
      desc: 'Ощутите переливы запахов, исходящих из морских глубин, уходящих в заросли тайги с добавлением ароматов бесскрайних просторов полей и лёгким привкусом зелёного яблока. Любовь к природе и МироЗданию проснётся в глубине Вашего сердца, открывая видение многообразия проявлений Творца, любящего Вас. Обретите свой величественный “дом творения”, узрев любовь в своём сердце.',
      color: 'зелёной'
    },
    {
      id: 8,
      label: 'Имбирный пряник',
      desc: 'Насладитесь гаммой запахов, олицетворяющих дровяную печь, в которой умелые руки любимой бабушки готовят имбирные пряники. Ожидание радости и чуда, предвкушение каскадов вкуса. Аромат поможет очистить пространство и навести идеальный порядок в мыслях. Раскроет Вас участниками процессов, как гармоничную мелодию, ткущую ткань МироЗдания.',
      color: 'бежевой'
    },
    {
      id: 9,
      label: 'Сливочная ириска',
      desc: 'Прикоснитесь к стихии огня. Очистите пространство от негатива. Насладитесь запахом молочных ирисок, медленно тающих на лесном костре. И сладковатое послевкусие наполнит ваши очищенные тела, пришедшие в гармонию при взаимодействии с источником жизни.',
      color: 'жёлтой'
    },
    {
      id: 10,
      label: 'Сандаловое дерево',
      desc: 'Погрузитесь в аромат ненавязчивой сладости векового древа. Ощутите глубину, основательность и нерушимость. Впитайте в себя вечность, унесясь к божественному источнику, источающего ауру чистоты. Почувствуйте луговые цветы, танцующие на ветру и ласкающие своими лепестками вековой ствол.',
      color: 'коричневой'
    },
    {
      id: 11,
      label: 'Сумеречный лес',
      desc: 'Останьтесь на едине с собой. Послушайте свой ум и своё тело. Уединитесь от всех, оказавшсь на опушках вечернего леса. Почувствуйте дружелюбие природы, ласкающей Вас нежными ароматами лиственных деревьев, добавляя лёгкую нотку хвои. Сгармонизируйте свои тела с природными каналами, очистите свои клетки. Наполнитесь спокойствием и чистотой.',
      color: 'хаки'
    },
    {
      id: 12,
      label: 'Любой аромат',
      desc: 'В этой емкости может находиться любой аромат, представленный на этом сайте. Отличие лишь во внешнем виде самого стакана.',
      color: 'белой с цветной крошкой'
    }
  ],
  selectedItem: null
}

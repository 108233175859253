<template lang="pug">
  v-tabs(
  class="horizontal-navigation"
    :height="height"
    :show-arrows="false"
    background-color="surface"
    color="primary"
    hide-slider
  )
    v-tab(
      v-for="(item, index) in items"
      :key="index"
      :ripple="false"
      :to="item.link"
      @click="$vuetify.goTo(item.link, options)"
    ) {{ item.label }}
</template>

<script>

import useVuetify from '@/composition/useVuetify'
import { computed, unref } from '@vue/composition-api'

export default {
  name: 'horizontalNavigation',
  props: {
    items: {
      type: Array,
      default: () => ([])
    },
    options: {
      type: Object,
      default: () => ({
        duration: 700,
        offset: 0,
        easing: 'easeInOutCubic'
      })
    }
  },
  setup() {
    const { breakpoint } = useVuetify()

    const height = computed(() => {
      if (unref(breakpoint).xsOnly) {
        return 25
      }
      return 30
    })

    return {
      height
    }
  }
}
</script>

<template lang="pug">
  v-container(class="footerSection")
    v-row(class="footerSection__content")
      v-col(cols="12" md="4" class="footerSection__column")
        .footerSection__navigation
          .footerSection__navigation-item(
            v-for="(item, index) in items" :key="index"
            @click="$vuetify.goTo(item.link, options)"
          ) {{ item.label }}
      v-col(cols="auto" class="footerSection__column")
        .footerSection__to-top(
          @click="$vuetify.goTo(topLink, options)"
        )
    v-row(class="footerSection__copyright")
     | istochnik@2022 Все права защищены
</template>

<script>
import useNavigation from '@/composition/navigation'

export default {
  name: 'footerSection',
  setup() {
    return {
      ...useNavigation()
    }
  }
}
</script>

<style lang="scss">
  @import '~vuetify/src/styles/styles.sass';

  .footerSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 5px;
    padding: 44px 25px;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      padding: 10px;
    }

    &__content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    &__column {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 27px;
    }

    &__navigation {
      display: flex;
      flex-direction: column;
      gap: 30px;
      font-size: 16px;
      line-height: 1.2;
      color: var(--v-secondary-base);
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        flex-direction: row;
        justify-content: center;
        gap: 15px;
        font-size: 11px;
        line-height: 1.2;
      }
    }

    &__navigation-item {
      border-bottom: 1px dashed transparent;
      width: fit-content;
      &:hover {
        cursor: pointer;
        color: var(--v-accent-base);
        border-color: var(--v-accent-base);
      }
    }

    &__to-top {
      background: url('~assets/footerSection/top-arrow.png') no-repeat;
      background-size: contain!important;
      display: flex;
      width: 73px;
      height: 73px;
      transition: margin-top 0.3s;

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        position: absolute;
        right: 10px;
        top: -100px;
        width: 50px;
        height: 50px;
      }

      &:hover {
        cursor: pointer;
        margin-top: -3px;
      }
    }

    &__copyright {
      font-size: 14px;
      line-height: 18px;
      color: var(--v-secondary-base);
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
</style>

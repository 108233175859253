<script>
import { computed, ref, unref } from '@vue/composition-api'
import useVuetify from '@/composition/useVuetify'

export default {
  name: 'catalogItemInModal',
  props: ['item'],
  setup() {
    const detailedInfoShown = ref(false)

    const { breakpoint } = useVuetify()

    const imageSizes = computed(() => {
      if (unref(breakpoint).mdOnly) {
        return { width: '220px', height: '322px' }
      }
      return { width: '260px', height: '380px' }
    })

    return {
      detailedInfoShown,
      imageSizes
    }
  }
}
</script>

<template lang="pug">
  v-row.catalogItemInModal__container
    v-col(cols="12" md="auto")
      .catalogItemInModal__header(v-if="$vuetify.breakpoint.smAndDown")
        .catalogItemInModal__title {{ item.label }}
        .catalogItemInModal__sub-title 170 мл в {{ item.color }} форме
      .catalogItemInModal__image-container
        v-img(
          :src="require(`@/assets/catalogSection/${item.id}.png?vuetify-preload`)" :key="item.id"
          lazy-src="~@/assets/catalogSection/empty.jpg"
          :width="imageSizes.width"
          :height="imageSizes.height"
          contain
        )
          template(v-slot:placeholder)
            v-row(
              class="fill-height ma-0"
              align="center"
              justify="center"
              aspect-ratio="1"
            )
              v-progress-circular(
                indeterminate
                color="grey lighten-5"
              )
    v-col(cols="12" md="7").catalogItemInModal__text-container
      .catalogItemInModal__header(v-if="$vuetify.breakpoint.mdAndUp")
        .catalogItemInModal__title {{ item.label }}
        .catalogItemInModal__sub-title 170 мл в {{ item.color }} форме
      .catalogItemInModal__desc
        .catalogItemInModal__desc-main {{ item.desc }}
        .catalogItemInModal__desc-more-btn(
          v-if="!detailedInfoShown"
          @click="detailedInfoShown = true"
        ) подробнее ↓
        .catalogItemInModal__desc-detailed(
          v-if="detailedInfoShown"
        )
          .catalogItemInModal__desc-detailed-item Свеча «ИСТОЧНИК» с эксклюзивным ароматом
          .catalogItemInModal__desc-detailed-item
            | Правила использования:
            br
            | Не употреблять в пищу. Не дарить нелюбимым.
            br
            | Использовать только по назначению.
            br
            | Соблюдать меры предосторожности как во всех ситуациях с использованием открытого огня
          .catalogItemInModal__desc-detailed-item
            | Способ применения:
            ul.catalogItemInModal__desc-detailed-item-list
              li Открыть крышку
              li Добыть огонь
              li Поджечь фитиль
              li Разместить стакан в помещении без сквозняков
              li Насладиться ароматом, заполняющим пространство
              li Для завершения сеанса накрыть свечу крышкой
              li Спрятать свечу «источник» в сокровенное место, доставая её и демонстрируя только близким.
              li Включить «режим ожидания» до следующей встречи
          .catalogItemInModal__desc-detailed-item
            | Состав:
            br
            | Зов души, любовь к мирозданию, желание проявиться, нежность и природные компоненты ( 100% соевый воск, хлопковый фитиль, натуральная аромаотдушка)
          .catalogItemInModal__desc-detailed-item
            | Произведено:
            br
            | По заказу души Анастасии с привлечением близких лиц
          .catalogItemInModal__desc-detailed-item
            | Юридический адрес:
            br
            | Средостение. Пространство сердца. Эманация любви
          .catalogItemInModal__desc-detailed-item
            | Характеристика (вес/объем):
            br
            | Количество любви вложенное в изготовление источника несоизмеримо с физической массой свечи и её объёмом (170 мл)
          .catalogItemInModal__desc-detailed-item
            | Срок годности:
            br
            | Пока сердце бьётся
          .catalogItemInModal__desc-detailed-item
            | Калорийность:
            br
            | В 20 г свечи возможность слияния с потоком изОбилия для получения любой калорийности, доступной вашему организму
      .catalogItemInModal__bottom-info
        | Возможны расхождения в точности передачи цвета.
        br
        | Наличие мелких пузырей не является браком, так как гипс - пористый материал.
</template>

<style lang="scss">
.catalogItemInModal {
  &__container {
    background: var(--v-background-second-base);
    border-radius: 20px!important;
    padding: 30px 40px;
    gap: 33px;
    margin: 20px 0;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      padding: 10px 15px;
      margin: 5px 0;
      gap: 10px;
    }
    @media #{map-get($display-breakpoints, 'md-only')} {
      padding: 15px 20px;
      margin: 10px 0;
      gap: 16px;
    }
  }

  &__image-container {
    padding: 20px!important;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    background: var(--v-background-base);
    height: 513px;
    width: 100%;
    border-radius: 25px;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      background: transparent;
      height: 380px;
      margin-top: 20px;
    }
    @media #{map-get($display-breakpoints, 'md-only')} {
      width: 250px;
      height: 352px;
    }
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    text-align: left;
    gap: 50px;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      padding: 10px;
      gap: 15px;
    }
    @media #{map-get($display-breakpoints, 'md-only')} {
      padding: 15px;
      gap: 25px;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: 0 auto;
    gap: 9px;
    color: var(--v-secondary-base);
  }

  &__title {
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    width: fit-content;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      font-size: 18px;
      line-height: 21px;
    }
  }

  &__sub-title {
    font-size: 20px;
    line-height: 24px;
    width: fit-content;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__desc {
    display: flex;
    flex-direction: column;
    gap: 35px;
    color: var(--v-primary-base);
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      gap: 15px;
    }
    @media #{map-get($display-breakpoints, 'md-only')} {
      gap: 17px;
    }
  }

  &__desc-main {
    font-size: 20px;
    line-height: 24px;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      font-size: 14px;
      line-height: 16px;
    }
    @media #{map-get($display-breakpoints, 'md-only')} {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__desc-more-btn {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    cursor: pointer;
    transition: color 0.2s;
    &:hover {
      color: var(--v-accent-base);
    }
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      font-size: 14px;
      line-height: 16px;
    }
    @media #{map-get($display-breakpoints, 'md-only')} {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__desc-detailed-item {
    color: var(--v-secondary-base);
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 18px;
  }

  &__bottom-info {
    color: var(--v-error-base);
    font-size: 14px;
    line-height: 18px;
  }
}
</style>

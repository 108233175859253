<template lang="pug">
  #app
    router-view
</template>

<script>

import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'App',
  setup(props, ctx) {
    const vuetify = computed(() => ctx.root.$vuetify)

    return {
      vuetify
    }
  }
})
</script>

<style lang="scss">
@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/MontserratAlternates-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}
#app {
  font-family: 'Montserrat', sans-serif;
}
</style>

<template lang="pug">
  v-container(class="productSectionMobile")
    h2(class="productSectionMobile__title") О свече
    .productSectionMobile__content
      v-col(cols="6" class="productSectionMobile__column productSectionMobile__column-left")
        v-img(
          class="productSectionMobile__image"
          :src="require(`@/assets/catalogSection/4.png?vuetify-preload`)"
          lazy-src="~@/assets/catalogSection/empty.jpg"
          :width="imageSizes.width"
          :height="imageSizes.height"
          contain
        )
          template(v-slot:placeholder)
            v-row(
              class="fill-height ma-0"
              align="center"
              justify="center"
              aspect-ratio="1"
            )
              v-progress-circular(
                indeterminate
                color="grey lighten-5"
              )
      v-col(cols="6" class="productSectionMobile__column productSectionMobile__column-right")
        ul.productSectionMobile__list
          li.productSectionMobile__text-item.productSectionMobile__text-item--last(style="min-width: 150px;")
            | Абсолютно каждая свеча
            br
            | сделана своими руками
          li.productSectionMobile__text-item.productSectionMobile__text-item--pre
            | До 40 часов горения
          li.productSectionMobile__text-item
            | В составе свечей
            br
            | только 100%
            br
            | натуральный
            br
            | соевый воск
          li.productSectionMobile__text-item.productSectionMobile__text-item--pre
            | Насыщенный
            br
            | аромат
          li.productSectionMobile__text-item.productSectionMobile__text-item--last 170 мл
</template>

<script>
import useVuetify from '@/composition/useVuetify'
import { computed, unref } from '@vue/composition-api'

export default {
  name: 'productSectionMobile',
  setup() {
    const { breakpoint } = useVuetify()

    const imageSizes = computed(() => {
      if (unref(breakpoint).xsOnly) {
        return { width: 95, height: 139 }
      }
      return { width: 180, height: 263 }
    })
    return {
      imageSizes
    }
  }
}
</script>
<style lang="scss">
  @import '~vuetify/src/styles/styles.sass';

  .productSectionMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 5px;
    min-height: 500px;
    padding: 70px;

    @media #{map-get($display-breakpoints, 'xs-only')} {
      padding: 70px 35px;
      min-height: 390px;
    }

    &__title {
      color: var(--v-accent-base);
      margin-bottom: 40px;
      line-height: 1;
      @media #{map-get($display-breakpoints, 'xs-only')} {
        margin-bottom: 15px;
      }
    }

    &__content {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &__column {
      display: flex;
      flex-direction: row;
      &-left {
        justify-content: flex-end;
      }
      &-right {
        justify-content: flex-start;
      }
    }

    &__image {
      display: flex;
    }

    &__list {
      list-style: none;
      display: flex;
      flex-direction: column;
    }

    &__text-item {
      font-size: 12px;
      line-height: 1.3;
      color: var(--v-primary-base);
      width: 100%;
      margin-bottom: 30px;
      @media #{map-get($display-breakpoints, 'xs-only')} {
        font-size: 10px;
        margin-bottom: 15px;
      }
      &--pre {
        margin-left: -15px;
      }
      &--last {
        margin-left: -45px;
        text-align: center;
        width: fit-content;
      }
    }
  }
</style>

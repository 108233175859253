<script>
/* eslint-disable global-require */
import catalogItem from '@/components/home/catalog/catalogItem.vue'
import useCatalog from '@/composition/useCatalog'
import { ref } from '@vue/composition-api'
import CatalogItemInModal from '@/components/home/catalog/catalogItemInModal.vue'

export default {
  name: 'catalogSection',
  components: {
    CatalogItemInModal,
    catalogItem
  },
  setup(props, ctx) {
    const dialog = ref(false)

    const { catalogItems, mSetSelectedItem, selectedItem } = useCatalog()

    const clickItemHandler = (item) => {
      mSetSelectedItem(item)
      ctx.root.$modal.show('selectedItemModal')
    }

    const closeDialog = () => {
      mSetSelectedItem(null)
      dialog.value = false
    }

    return {
      catalogItems,
      clickItemHandler,
      mSetSelectedItem,
      selectedItem,
      dialog,
      closeDialog
    }
  }
}
</script>

<template lang="pug">
  v-container(class="catalogSection")
    v-col(cols="12" class="justify-center py-5" align="center")
      h2(class="catalogSection__title") Каталог
      h3(class="catalogSection__sub-title")
        |  Предлагаю Вам знакомство
        br(v-if="$vuetify.breakpoint.smAndDown")
        |  с вариантами моего творечства
        br
        br(v-if="$vuetify.breakpoint.smAndDown")
        |  Свечи и формы изготавливаются
        br(v-if="$vuetify.breakpoint.smAndDown")
        |  вручную из природных материалов
        br(v-if="$vuetify.breakpoint.smAndDown")
        |  с добавлением ароматических отдушек
      p(class="catalogSection__sub-title") Объём 170г
    v-col(cols="12" class="pa-0")
      v-row(no-gutters)
        catalog-item(
          v-for="(item, index) in catalogItems" :key="index"
          :item="item"
          @on-click-item="clickItemHandler(item)"
        )
        modal(
          name="selectedItemModal"
          classes="catalogSection__modal"
          height="auto"
          scrollable
          width="80%"
          :max-width="1017"
          :adaptive="true"
          :reset="true"
        )
          .catalogSection__modal-content(v-if="selectedItem")
            .catalogSection__modal-arrow.catalogSection__modal-arrow-left(
              v-if="selectedItem.id > 1 && $vuetify.breakpoint.mdAndUp"
              @click="mSetSelectedItem(catalogItems[selectedItem.id - 2])"
            )
            .catalogSection__modal-arrow.catalogSection__modal-arrow-right(
              v-if="selectedItem.id < catalogItems.length && $vuetify.breakpoint.mdAndUp"
              @click="mSetSelectedItem(catalogItems[selectedItem.id])"
            )
            catalog-item-in-modal(
              :item="selectedItem"
            )
</template>

<style lang="scss">
.catalogSection {
  color: var(--v-secondary-base);
  padding: 20px;
  border-radius: 0 0 12px 12px;
  overflow: hidden;
  margin-bottom: 30px;
  text-align: center;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 5px;
  }

  &__title {
    color: var(--v-accent-base);
    margin-bottom: 40px;
  }
  &__sub-title {
    color: var(--v-primary-base);
    margin-bottom: 20px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      font-size: 0.8em;
    }
  }
  &__modal {
    overflow: initial!important;
    background: var(--v-background-second-base);
    border-radius: 20px!important;
  }

  &__modal-content {
    background: var(--v-backgroundSecond-base);
    border-radius: 20px!important;
  }

  .vm--overlay {
    background: rgba(0, 0, 0, 0.4);
  }

  &__modal-arrow {
    height: 72px;
    width: 72px;
    display: block;
    position: absolute;
    top: calc(50% - 31px);
    cursor: pointer;
    box-shadow: none;
    transition: box-shadow 0.2s;
    border-radius: 50%;
    background-size: contain!important;
    @media #{map-get($display-breakpoints, 'md-only')} {
      height: 50px;
      width: 50px;
      top: calc(50% - 25px);
    }
    &:hover {
      box-shadow: -1px 3px 8px rgb(0,0,0,.3);
      cursor: pointer;
    }
    &-left {
      background: url('~@/assets/catalogSection/left-arrow.svg') no-repeat;
      left: -100px;
      @media #{map-get($display-breakpoints, 'md-only')} {
        left: -75px;
      }
    }

    &-right {
      background: url('~@/assets/catalogSection/right-arrow.svg') no-repeat;
      right: -100px;
      @media #{map-get($display-breakpoints, 'md-only')} {
        right: -75px;
      }
    }
  }
}
</style>

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ru from 'vuetify/src/locale/ru.ts'
import '@/styles/overrides.sass'
import Fragment from 'vue-fragment'

Vue.use(Fragment.Plugin)
Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { ru },
    current: 'ru'
  },
  treeShake: true,
  icons: {
    iconfont: 'mdi',
    values: {
      close: 'mdi-close',
      menu: 'mdi-menu',
      telegram: 'mdi-telegram',
      whatsapp: 'mdi-whatsapp',
      vk: 'mdi-vk',
      github: 'mdi-github'
    }
  },
  breakpoint: {
    thresholds: {
      xs: 574,
      sm: 935,
      md: 1239,
      lg: 1879
    },
    scrollBarWidth: 24
  },
  theme: {
    options: {
      // чтобы исопльзовать переменные в styles
      customProperties: true
    },
    themes: {
      light: {
        primary: '#41423E',
        secondary: '#626C78',
        accent: '#9E88B3',
        background: '#f5f1da',
        backgroundSecond: '#FFFEFB',
        error: '#A46363'
      }
    }
  }
})

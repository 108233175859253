<template lang="pug">
  fragment
    v-container(fluid class="main-section")
      video-background(
        :src="require(`@/assets/anim.mp4`)"
        :poster="require(`@/assets/bg.png`)"
        style="height: 100vh; max-height: 1080px"
        objectFit="cover"
        :loop="false"
      )
        topSection(id="main" v-intersect="{ handler: onIntersect, options: { threshold: 0.8 } }")
    v-container(fluid class="section background-second")
      catalogSection(id="catalog" v-intersect="{ handler: onIntersect, options: { threshold: 0.5 } }")
    v-container(fluid class="background-second pa-0")
      .product-section
        productSection(v-if="$vuetify.breakpoint.mdAndUp" id="about-product" v-intersect="{ handler: onIntersect, options: { threshold: 0.8 } }")
        productSectionMobile(v-if="$vuetify.breakpoint.smAndDown" id="about-product" v-intersect="{ handler: onIntersect, options: { threshold: 0.8 } }")
    v-container(fluid class="background-second")
      shopsSection(id="shops" v-intersect="{ handler: onIntersect, options: { threshold: 0.8 } }")
    v-container(fluid class="background-second pa-0")
      .contacts-section
        contactsSection(id="contacts" v-intersect="{ handler: onIntersect, options: { threshold: 0.8 } }")
    v-container(fluid class="background-second")
      info-section(id="info" v-intersect="{ handler: onIntersect, options: { threshold: 0.8 } }")
    v-container(fluid class="section background")
      footer-section(id="footer" v-intersect="{ handler: onIntersect, options: { threshold: 0.8 } }")
</template>

<script>
import topSection from '@/components/home/topSection.vue'
import catalogSection from '@/components/home/catalogSection.vue'
import ProductSection from '@/components/home/productSection.vue'
import ProductSectionMobile from '@/components/home/productSectionMobile.vue'
import ShopsSection from '@/components/home/shopsSection.vue'
import contactsSection from '@/components/home/contactsSection.vue'
import InfoSection from '@/components/home/infoSection.vue'
import FooterSection from '@/components/home/footerSection.vue'

export default {
  name: 'Home',
  components: {
    topSection,
    catalogSection,
    ProductSection,
    ProductSectionMobile,
    ShopsSection,
    contactsSection,
    InfoSection,
    FooterSection
  },
  setup(props, { root }) {
    const onIntersect = (entries) => {
      const sectionId = entries[0].target.id
      root.$router.replace({ name: root.$route.name, hash: `#${sectionId}` })
    }
    return {
      onIntersect
    }
  }
}
</script>

<style lang="scss">
  .main-section {
    padding: 0!important;
    background: var(--v-background-base);
    background-size: 80%;
  }
  .product-section {
    background: var(--v-background-base);
    border-radius: 300px 0 0 300px;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      width: 100%;
    }
  }
  .contacts-section {
    background: var(--v-background-base);
    border-radius: 0 300px 300px 0;
  }
</style>

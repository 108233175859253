import { computed, getCurrentInstance } from '@vue/composition-api'

const useVuetify = () => {
  const vm = getCurrentInstance()

  const breakpoint = computed(() => vm.proxy.$vuetify.breakpoint)
  return {
    breakpoint
  }
}

export default useVuetify

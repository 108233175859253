<script>
export default {
  name: 'infoSection'
}
</script>

<template lang="pug">
  v-container(class="infoSection")
    v-row(no-gutters class="justify-center" align="center")
      h3(class="infoSection__text")
        | Дизайн разработан лично.
        br
        | При создании матриц и упаковок задействовались российские специалисты.
        br
        br
        | Возможны расхождения в точности передачи цвета.
        br
        | Наличие мелких пузырей не является браком, так как гипс - пористый материал.

</template>

<style lang="scss">
.infoSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--v-secondary-base);
  padding: 50px 20px;
  text-align: center;
  min-height: 60vh;

  &__text {
    font-size: 20px;
    line-height: 1.3;
    color: var(--v-primary-base);

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      font-size: 12px;
    }
  }
}
</style>

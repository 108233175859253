<template lang="pug">
  v-app
    top-bar
    v-main
      router-view
    leftMobileMenu
</template>

<script>
import topBar from '@/layout/topBar/topBar.vue'
import leftMobileMenu from '@/layout/leftSide/leftMobileMenu.vue'

export default {
  name: 'MainLayout',
  components: {
    topBar,
    leftMobileMenu
  },
  head: {
    link: [
      {
        rel: 'icon',
        // eslint-disable-next-line global-require
        href: require('@/assets/logos/1.svg')
      }
    ]
  }
}
</script>

<template lang="pug">
  v-container(class="productSection")
    v-row(class="justify-center")
      v-col(cols="4" class="productSection__column productSection__column--left")
        .productSection__text-item Каждая свеча сделана своими руками
        .productSection__text-item
          | До 40 часов
          br
          | горения
      v-col(cols="auto" class="productSection__column")
        v-img(
          :src="require(`@/assets/catalogSection/4.png?vuetify-preload`)"
          lazy-src="~@/assets/catalogSection/empty.jpg"
          max-width="260px"
          height="380px"
          contain
        )
          template(v-slot:placeholder)
            v-row(
              class="fill-height ma-0"
              align="center"
              justify="center"
              aspect-ratio="1"
            )
              v-progress-circular(
                indeterminate
                color="grey lighten-5"
              )
      v-col(cols="4" class="productSection__column productSection__column--right")
        .productSection__text-item.productSection__text-item--right Насыщенный аромат
        .productSection__text-item.productSection__text-item--right 170 мл
        .productSection__text-item.productSection__text-item--right В составе свечей только 100% натуральный соевый воск

</template>

<script>
export default {
  name: 'productSection'
}
</script>
<style lang="scss">
  @import '~vuetify/src/styles/styles.sass';

  .productSection {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin-top: 5px;
    min-height: 500px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      padding: 20px;
    }

    &__column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 27px;
      &--left {
        align-self: flex-start;
        @media #{map-get($display-breakpoints, 'xl-only')} {
          margin-top: 50px;
        }
        @media #{map-get($display-breakpoints, 'md-only')} {
          margin-top: 40px;
        }
      }
      &--right {
        align-self: flex-end;
        @media #{map-get($display-breakpoints, 'xl-only')} {
          margin-bottom: 30px;
        }
        @media #{map-get($display-breakpoints, 'md-only')} {
          margin-bottom: 20px;
        }
      }
    }

    &__text-item {
      font-size: 20px;
      line-height: 1.3;
      color: var(--v-primary-base);
      text-align: right;
      width: 264px;
      align-self: flex-end;
      justify-content: flex-start;

      &--right {
        text-align: left;
        align-self: flex-start;
      }
      @media #{map-get($display-breakpoints, 'md-only')} {
        font-size: 18px;
      }
      @media #{map-get($display-breakpoints, 'sm-only')} {
        font-size: 2em
      }
      @media #{map-get($display-breakpoints, 'xs-only')} {
        font-size: 1.5em
      }
    }
  }
</style>

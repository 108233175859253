<script>
import useVuetify from '@/composition/useVuetify'
import { computed, unref } from '@vue/composition-api'

export default {
  name: 'catalogItem',
  props: ['item'],
  setup() {
    const { breakpoint } = useVuetify()

    const imageSizes = computed(() => {
      if (unref(breakpoint).xsOnly) {
        return { width: '95px', height: '139px' }
      }
      return { width: '157px', height: '209px' }
    })

    return {
      imageSizes
    }
  }
}
</script>

<template lang="pug">
  v-col(cols="6" md="3" class="catalogItem")
    .catalogItem__container(@click="$emit('on-click-item', item.id)")
      .catalogItem__image-container
        v-row(no-gutters justify="center")
          v-img(
            :src="require(`@/assets/catalogSection/${item.id}.png?vuetify-preload`)"
            lazy-src="~@/assets/catalogSection/empty.jpg"
            :key="item.id"
            :width="imageSizes.width"
            :height="imageSizes.height"
            contain
          )
            template(v-slot:placeholder)
              v-row(
                class="fill-height ma-0"
                align="center"
                justify="center"
                aspect-ratio="1"
              )
                v-progress-circular(
                  indeterminate
                  color="grey lighten-5"
                )
      .catalogItem__bottom
        h4 {{ item.label }}
        p ПОДРОБНЕЕ
</template>

<style lang="scss">
.catalogItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    height: 100%;
    max-width: 208px;
    border-radius: 25px;
    padding: 10px;
    box-shadow: none;
    transition: box-shadow 0.5s;

    @media #{map-get($display-breakpoints, 'xs-only')} {
      padding: 4px;
      margin: 5px;
    }
    &:hover {
      box-shadow: -1px 3px 8px rgb(200 189 131 / 50%);
      cursor: pointer;
    }
  }

  &__image-container {
    padding: 20px;
    justify-items: center;
    align-items: center;
    background: var(--v-background-base);
    max-height: 245px;
    height: auto;
    border-radius: 25px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      padding: 10px;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    justify-content: space-between;
    gap: 8px;
    padding: 10px 5px;
    color: var(--v-secondary-base);
    font-size: 14px;
    line-height: 1.2;
    text-align: left;
    width: 100%;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      font-size: 0.55em;
    }
    p {
      margin: 0;
      font-weight: bold;
      color: var(--v-primary-base);
      letter-spacing: 3px;
    }
  }
}
</style>

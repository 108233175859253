<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'shopItem',
  props: ['shop']
})
</script>

<template lang="pug">
  v-col(cols="12" sm="6" md="4" class="shopItem")
    .shopItem__container
      .shopItem__label {{ shop.label }}
      .shopItem__info
        .shopItem__address
          .shopItem__address-label Адрес:
          .shopItem__address-value {{ shop.address }}
          .shopItem__address-link-block
            a(class="shopItem__address-link" :href="shop.addressLink" target="_blank") Показать на карте
        .shopItem__phone-block
          .shopItem__phone-label Телефон:
          a(class="shopItem__phone" :href="`tel:${shop.phone.value}`" target="_blank") {{ shop.phone.label }}
        .shopItem__schedule
          .shopItem__schedule-label {{ shop.schedule.label }}
          ul.shopItem__schedule-list
            li.shopItem__schedule-list-item(v-for="(item, index) in shop.schedule.items" :key="index")
              .shopItem__schedule-list-item-label {{ item.label }}
              .shopItem__schedule-list-item-value {{ item.value }}
</template>

<style lang="scss">
.shopItem {
  display: flex;
  flex-direction: column;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 15px;
    padding: 20px;
    height: 100%;
    border-radius: 25px;
    background: var(--v-background-base);
    overflow: hidden;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      margin: 10px;
      padding: 15px;
    }
  }

  &__label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
    line-height: 25px;
    padding: 5px 20px;
    min-height: 60px;
    font-weight: bold;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      font-size: 16px;
      line-height: 20px;
      min-height: 40px;
    }
    @media #{map-get($display-breakpoints, 'md-only')} {
      font-size: 18px;
      line-height: 22px;
      min-height: 55px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    gap: 15px;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      gap: 7px;
    }
    @media #{map-get($display-breakpoints, 'md-only')} {
      gap: 10px;
    }
  }

  &__address {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 16px;
    line-height: 1.2;
    min-height: 75px;
    color: var(--v-primary-base);
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      min-height: 50px;
      font-size: 12px;
    }
    @media #{map-get($display-breakpoints, 'md-only')} {
      font-size: 14px;
    }
  }

  &__address-label, &__schedule-label, &__phone-label {
    font-size: 15px;
    font-weight: bold;
    color: var(--v-secondary-base);
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      font-size: 11px;
    }
    @media #{map-get($display-breakpoints, 'md-only')} {
      font-size: 13px;
    }
  }

  &__address-link, &__phone {
    padding: 3px 0;
    text-transform: none;
    text-decoration: none;
    font-size: 0.8em;
    border-bottom: 1px dashed;
    width: fit-content;
    &:hover {
      color: var(--v-accent-base);
      border-bottom-color: var(--v-accent-base);
    }
  }

  &__schedule {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__schedule-list {
    margin: 0;
    list-style: none;
    padding: 0!important;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__schedule-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    font-size: 16px;
    line-height: 1.2;
    color: var(--v-primary-base);
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      font-size: 12px;
    }
    @media #{map-get($display-breakpoints, 'md-only')} {
      font-size: 14px;
    }
  }

  &__schedule-list-item-value {
    font-size: 15px;
    font-weight: bold;
    color: var(--v-secondary-base);
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      font-size: 12px;
    }
    @media #{map-get($display-breakpoints, 'md-only')} {
      font-size: 14px;
    }
  }
}
</style>

<template lang="pug">
  v-container(class="shopsSection")
    v-row(no-gutters class="justify-center py-5" align="center")
      h2(class="shopsSection__title") Где купить?
    v-row(no-gutters class="justify-start")
      shop-item(
        v-for="(item, index) in shops" :key="index"
        :shop="item"
      )

</template>

<script>
import useShops from '@/composition/useShops'
import ShopItem from '@/components/home/shopsSection/shopItem.vue'

export default {
  name: 'shopsSection',
  components: {
    ShopItem
  },
  setup() {
    const { shops } = useShops()

    return {
      shops
    }
  }
}
</script>
<style lang="scss">
  @import '~vuetify/src/styles/styles.sass';

  .shopsSection {
    color: var(--v-secondary-base);

    border-radius: 0 0 12px 12px;
    margin-bottom: 30px;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      padding: 0;
    }

    &__title {
      color: var(--v-accent-base);
      margin-bottom: 40px;
    }
  }
</style>
